<template>
  <v-container class="pa-0 ma-0" fluid>
    <TermsOfServiceDialog ref="terms" @accepted="onAccepted" />
    <v-row :style="{ height: '33vh', 'min-height': '250px' }" align="center">
      <v-col align="center">
        <v-img src="../assets/1lifelogo.png" max-width="300px"> </v-img>
      </v-col>
    </v-row>
    <v-row align="end" class="msaBlue" justify="center">
      <v-col v-if="!showResult" align="center" :cols="isMobile ? 10 : 4">
        <v-row align="center">
          <v-col><span class="text-h4 white--text">Password Reset</span></v-col>
        </v-row>
        <v-row>
          <v-col align="left">
            <v-alert color="info" class="white--text">
              Password must meet the following requirements:

              <ul>
                <li>Contain at least one lower case letter</li>
                <li>Contain at least one capital letter</li>
                <li>Contain at least one number</li>
                <li>Be at least 6 characters in length</li>
                <li>Cannot not contain the # character</li>
              </ul>
            </v-alert>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              filled
              depressed
              hide-details
              background-color="white"
              v-model="password"
              label="New password"
              color="msaBlue"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              filled
              depressed
              hide-details
              background-color="white"
              v-model="password2"
              label="Confirm new password"
              color="msaBlue"
              :style="{ 'border-radius': '5px' }"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              class="green white--text"
              :disabled="buttonDisabled"
              @click="vaildate"
              block
            >
              Set New Password
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else align="center">
        <v-row align="center">
          <v-col
            ><v-alert color="success" class="white--text" max-width="650px">
              {{ resultMessage }}</v-alert
            ></v-col
          >
        </v-row>
      </v-col>
    </v-row>
    <v-row class="msaBlue">
      <v-col align="center">
        <v-btn text color="white" @click="backToSignIn" small>
          Back to sign in
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TermsOfServiceDialog from '../components/TermsOfServiceDialog.vue';
import { configureUser, logInUser } from '../global/functions.js';
export default {
  components: { TermsOfServiceDialog },
  name: 'NewPasswordPage',
  data() {
    return {
      password: '',
      password2: '',
      resultMessage: '',
      showPassword: false,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    buttonDisabled() {
      if (this.password.length == 0) {
        return true;
      }
      if (this.password2.length == 0) {
        return true;
      }
      return false;
    },
    showResult() {
      return this.resultMessage.length > 0;
    },
  },
  methods: {
    onAccepted() {
      this.versionCheck();
      logInUser(this);
    },
    backToSignIn() {
      this.$router.push({ name: 'UserLogin' });
    },
    vaildate() {
      if (this.password != this.password2) {
        this.$root.showMessage(
          'Invalid Password',
          'Passwords do not match',
          () => false,
          () => null,
          'OK',
          '',
        );
        return;
      }
      var result = this.$helpers.validatePasswordAll(this.password);
      if (result.length === 0) {
        this.resetPassword();
        return;
      }
      this.$root.showMessage(
        'Invalid Password',
        result,
        () => false,
        () => null,
        'OK',
        '',
      );
    },
    resetPassword() {
      const url = 'set-new-password?format=json';
      const params = {
        password: this.password,
        requestKey: this.$route.query.requestKey,
        loginKey: this.$route.query.loginKey,
        loaderText: 'Sending...',
      };
      this.$axios
        .post(url, params)
        .then((response) => {
          if (response.data.autoLogin) {
            this.$store.commit('invalidateStore');
            var user = configureUser(this, response.data.user);
            var token = response.data.user.token;
            if (user.acceptedTermsOfService == 0) {
              this.$refs.terms.show(user, token);
              return;
            }
            this.$store.commit('updateUser', user);
            this.$store.commit('updateJwtToken', token);
            this.versionCheck();
            logInUser(this);
          } else {
            this.resultMessage = 'Password reset successfully.';
          }
        })
        .catch((error) => error);
    },
    versionCheck() {
      const url = 'version-check?format=json';
      this.$axios
        .post(url, { loadText: 'Checking updates...' })
        .then((response) => {
          if (
            process.env.NODE_ENV != 'development' &&
            this.$root.appVersion != response.data
          ) {
            this.$root.showMessage(
              'New version available',
              'A new version of 1Life software is available.<br> Please reload the page to get the latest version.',
              () => this.$router.go(),
              null,
              'Reload Page',
              null,
            );
          }
        });
    },
  },
};
</script>
